@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;800&family=Montserrat:wght@400;600&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
