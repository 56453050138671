.notFoundPage {
  width: 100%;
  height: 100vh;
  background-color: #575963;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.notFoundPage * {
  font-family: "Montserrat", Helvetica, sans-serif;
  color: #d6d6d6;
}
.notFoundPage h1 {
  text-align: center;
  margin: 10px 10px;
}
.notFoundPage .zoom-area {
  max-width: 490px;
  margin: 15px auto 15px;
  font-size: 19px;
  text-align: center;
}
.notFoundPage .link-container {
  text-align: center;
}

.notFoundPage .error-container {
  text-align: center;
  font-size: 180px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 5px 5px;
}
.notFoundPage .error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #ffb485;
}
.notFoundPage .error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.notFoundPage .error-container > span:nth-of-type(1) {
  color: #d1f2a5;
  animation: colordancing 4s infinite;
}
.notFoundPage .error-container > span:nth-of-type(3) {
  color: #f56991;
  animation: colordancing2 4s infinite;
}
.notFoundPage .error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}

.notFoundPage .error-container > span:nth-of-type(2):before,
.notFoundPage .error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.notFoundPage .error-container > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.notFoundPage .screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

.notFoundPage .button * {
  color: #575963 !important;
}

@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #d1f2a5;
  }
  25% {
    color: #f56991;
  }
  50% {
    color: #ffc48c;
  }
  75% {
    color: #effab4;
  }
  100% {
    color: #d1f2a5;
  }
}
@keyframes colordancing2 {
  0% {
    color: #ffc48c;
  }
  25% {
    color: #effab4;
  }
  50% {
    color: #d1f2a5;
  }
  75% {
    color: #f56991;
  }
  100% {
    color: #ffc48c;
  }
}
@media screen and (max-width: 600px) {
  .notFoundPage .error-container > span:nth-of-type(2) {
    width: 60px;
    height: 60px;
    border-radius: 999px;
  }

  .notFoundPage .error-container {
    text-align: center;
    font-size: 90px;
    font-family: "Catamaran", sans-serif;
    font-weight: 800;
    margin: 20px 15px;
  }

  @keyframes shadowsdancing {
    0% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
    25% {
      box-shadow: inset 15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 15px 0 rgba(209, 242, 165, 0.4),
        inset -15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 -15px 0 rgba(255, 196, 140, 0.4);
    }
    50% {
      box-shadow: inset 15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 15px 0 rgba(245, 105, 145, 0.4),
        inset -15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 -15px 0 rgba(239, 250, 180, 0.4);
    }
    75% {
      box-shadow: inset 15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 15px 0 rgba(255, 196, 140, 0.4),
        inset -15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 -15px 0 rgba(209, 242, 165, 0.4);
    }
    100% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
  }
}

/* demo stuff */
