@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@400;800&family=Montserrat:wght@400;600&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap);
.NotFoundPage_notFoundPage__3hOrf {
  width: 100%;
  height: 100vh;
  background-color: #575963;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 100px;
}
.NotFoundPage_notFoundPage__3hOrf * {
  font-family: "Montserrat", Helvetica, sans-serif;
  color: #d6d6d6;
}
.NotFoundPage_notFoundPage__3hOrf h1 {
  text-align: center;
  margin: 10px 10px;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_zoom-area__3hBkN {
  max-width: 490px;
  margin: 15px auto 15px;
  font-size: 19px;
  text-align: center;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_link-container__RMnak {
  text-align: center;
}

.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 {
  text-align: center;
  font-size: 180px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 5px 5px;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #ffb485;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(1) {
  color: #d1f2a5;
  -webkit-animation: NotFoundPage_colordancing__1fDzb 4s infinite;
          animation: NotFoundPage_colordancing__1fDzb 4s infinite;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(3) {
  color: #f56991;
  -webkit-animation: NotFoundPage_colordancing2__2w5e5 4s infinite;
          animation: NotFoundPage_colordancing2__2w5e5 4s infinite;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}

.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(2):before,
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  -webkit-animation: NotFoundPage_shadowsdancing__363TV 4s infinite;
          animation: NotFoundPage_shadowsdancing__363TV 4s infinite;
}
.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_screen-reader-text__1H6no {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

.NotFoundPage_notFoundPage__3hOrf .NotFoundPage_button__DIEFP * {
  color: #575963 !important;
}

@-webkit-keyframes NotFoundPage_shadowsdancing__363TV {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}

@keyframes NotFoundPage_shadowsdancing__363TV {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@-webkit-keyframes NotFoundPage_colordancing__1fDzb {
  0% {
    color: #d1f2a5;
  }
  25% {
    color: #f56991;
  }
  50% {
    color: #ffc48c;
  }
  75% {
    color: #effab4;
  }
  100% {
    color: #d1f2a5;
  }
}
@keyframes NotFoundPage_colordancing__1fDzb {
  0% {
    color: #d1f2a5;
  }
  25% {
    color: #f56991;
  }
  50% {
    color: #ffc48c;
  }
  75% {
    color: #effab4;
  }
  100% {
    color: #d1f2a5;
  }
}
@-webkit-keyframes NotFoundPage_colordancing2__2w5e5 {
  0% {
    color: #ffc48c;
  }
  25% {
    color: #effab4;
  }
  50% {
    color: #d1f2a5;
  }
  75% {
    color: #f56991;
  }
  100% {
    color: #ffc48c;
  }
}
@keyframes NotFoundPage_colordancing2__2w5e5 {
  0% {
    color: #ffc48c;
  }
  25% {
    color: #effab4;
  }
  50% {
    color: #d1f2a5;
  }
  75% {
    color: #f56991;
  }
  100% {
    color: #ffc48c;
  }
}
@media screen and (max-width: 600px) {
  .NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 > span:nth-of-type(2) {
    width: 60px;
    height: 60px;
    border-radius: 999px;
  }

  .NotFoundPage_notFoundPage__3hOrf .NotFoundPage_error-container__2Vl57 {
    text-align: center;
    font-size: 90px;
    font-family: "Catamaran", sans-serif;
    font-weight: 800;
    margin: 20px 15px;
  }

  @-webkit-keyframes NotFoundPage_shadowsdancing__363TV {
    0% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
    25% {
      box-shadow: inset 15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 15px 0 rgba(209, 242, 165, 0.4),
        inset -15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 -15px 0 rgba(255, 196, 140, 0.4);
    }
    50% {
      box-shadow: inset 15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 15px 0 rgba(245, 105, 145, 0.4),
        inset -15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 -15px 0 rgba(239, 250, 180, 0.4);
    }
    75% {
      box-shadow: inset 15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 15px 0 rgba(255, 196, 140, 0.4),
        inset -15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 -15px 0 rgba(209, 242, 165, 0.4);
    }
    100% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
  }

  @keyframes NotFoundPage_shadowsdancing__363TV {
    0% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
    25% {
      box-shadow: inset 15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 15px 0 rgba(209, 242, 165, 0.4),
        inset -15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 -15px 0 rgba(255, 196, 140, 0.4);
    }
    50% {
      box-shadow: inset 15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 15px 0 rgba(245, 105, 145, 0.4),
        inset -15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 -15px 0 rgba(239, 250, 180, 0.4);
    }
    75% {
      box-shadow: inset 15px 0 0 rgba(239, 250, 180, 0.4),
        inset 0 15px 0 rgba(255, 196, 140, 0.4),
        inset -15px 0 0 rgba(245, 105, 145, 0.4),
        inset 0 -15px 0 rgba(209, 242, 165, 0.4);
    }
    100% {
      box-shadow: inset 15px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 15px 0 rgba(239, 250, 180, 0.4),
        inset -15px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -15px 0 rgba(245, 105, 145, 0.4);
    }
  }
}

/* demo stuff */

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

